import React from "react";
import CreditoFuturoImg from "../../imagenes/emprendefuturo.png";
import apiVisitas from "../../services/visitasApi";

class EmprendeFuturo extends React.Component {

    agregarEmprendeFuturo = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Emprende Futuro" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarEmprendeFuturo();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>EMPRENDE FUTURO</h1>
                    <h2 className="mb-3">
                      Creemos en tu espíritu emprendedor, ofrecemos la
                      posibilidad de empezar con tu microempresa o surtir tu
                      negocio, con tu crédito emprende futuro, obtienes el
                      financiamiento necesario para alcanzar tus metas.
                    </h2>
                    <h2>Hasta $3.000, a 24 meses plazo</h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={CreditoFuturoImg}
                      className="img-fluid animated rounded w-75"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <h5>
                        En Coac. Futuro Lamanense confiamos en el espíritu
                        emprendedor de toda la población, por ello otorgamos el
                        crédito emprende futuro con la tasa de interés mas baja
                        para estudiantes y emprendedores sin score crediticio.
                      </h5>

                      <br />
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Requisitos:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        - Cédula de identidad y papeleta de votación del titular
                        (Estudiantes)
                      </h5>
                      <h5 className="ps-4">- Tener entre 18 y 35 años.</h5>
                      <h5 className="ps-4">
                        - Jusitificación del negocio / Proformas (Emprendedores)
                      </h5>
                      <h5 className="ps-4">
                        - Justificación de ingresos (Roles de pago, RUC o RIMPE,
                        declaraciones o facturas, certificado comercial).
                      </h5>
                      <h5 className="ps-4">- Planilla de servicio básico.</h5>
                      <h5 className="ps-4">
                        - Original de impuesto predial (en caso de tenerlo).
                      </h5>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Características del crédito:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        - <strong>AGILIDAD:</strong> Tu crédito se aprueba en un
                        plazo de 24 a 48 horas de acuerdo al avalúo.
                      </h5>
                      <h5 className="ps-4">
                        - <strong>FLEXIBILIDAD:</strong> Pago justo de acuerdo
                        con la tasa de interés aplicada.
                      </h5>
                      <h5 className="ps-4">
                        - <strong>BENEFICIOS:</strong> Seguro médico para el
                        deudor, cónyuge y 3 dependientes menos de 23 años,
                        seguro de gravamen, también puedes acceder a nuestros
                        servicios de odontología, peluquería y un fondo
                        mortuorio de hasta $5.000.
                      </h5>
                      <h5 className="ps-4">
                        - <strong>FACILIDAD:</strong> Puedes cancelar las cuotas
                        de tu crédito desde cualquier agencia de la Coac. Futuro
                        Lamanense o por transferencias electrónicas.
                      </h5>
                      <h5 className="ps-4">- Tasas de interés del 17%</h5>
                      <br />
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default EmprendeFuturo;