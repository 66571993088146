import React, { Component } from 'react';

export default class ImageComponent extends Component {
  render() {
    // Desestructurar accesorios con valores predeterminados.
    const {
      urlImage,
      styleCard = false,
      widthImg = "100%",
      heightImg = "100%",
      objectFit = "contain",
      cursorP = false,
      onClick
    } = this.props;

    // Definir el objeto de estilo para la imagen.
    const imgStyle = {
      width: widthImg,
      height: heightImg,
      objectFit: objectFit,
      cursor: cursorP ? "pointer" : "auto"  // Establece el estilo del cursor según el cursorP
    };

    // Renderiza el elemento img con los accesorios y el estilo dados.
    return (
      <img
        src={urlImage}
        style={imgStyle}
        className={`${styleCard ? "card-img-top" : ""} m-auto rounded img-fluid`}
        alt={`img-${urlImage}`}
        onClick={onClick}
      />
    );
  }
}
