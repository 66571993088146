import React from "react";
import DepositosProtegidos from './../../imagenes/depositosprotegidos.png';

class SeguroDepositos extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="cta" className="cta">
                    <div className="container" data-aos="zoom-in">
                        <div className="row">
                            <div className="col-lg-8 text-center text-lg-start">
                                <img src={DepositosProtegidos} className="w-100 rounded" />
                            </div>
                            <div className="col-lg-4  text-center">
                                <p>
                                    <a className="cta-btn align-middle" href="https://www.cosede.gob.ec/conoce-tu-monto-de-cobertura/" target="_blank">Conoce tu monto de cobertura</a>
                                </p>
                                <p>
                                    <a className="cta-btn align-middle" href="https://educate.cosede.gob.ec/" target="_blank">Plataforma Edúcate</a>
                                </p>
                                <p>
                                    <a className="cta-btn align-middle" href="https://www.cosede.gob.ec/" target="_blank">Más información</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default SeguroDepositos;