import React from "react";
import creditoecofuturoMobile from './../../../imagenes/baners-paginas/bannerecofuturo.png';

class EcoFuturo extends React.Component {
    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>ECO FUTURO</h1>
                    <h2 className="mb-3">
                      Crédito destinado a negocios sostenibles, proyectos con
                      energías renovables, buenas prácticas ambientales y
                      movilidad eléctrica.
                    </h2>
                    <h2>Monto máximo de $80.000, hasta 102 meses plazo</h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={creditoecofuturoMobile}
                      className="img-fluid rounded image-credito-ecofuturo"
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <h5>
                        Enfocados en el cuidado del medio ambiente financiamos
                        materias primas, insumos, equipos y maquinaria para
                        proyectos con destino ecológico. Incentivamos la
                        Responsabilidad Ambiental al apoyar proyectos con
                        perspectiva sostenible, que aporten eficiencia
                        energética, sistemas de riego que optimicen agua y
                        buenas prácticas de agricultura.
                      </h5>
                      <h5 className="mt-3">
                        El proyecto debe estar dentro de estas categorías:
                      </h5>
                      <h5>
                        <li> Proyectos de energía renovable</li>
                        <li> Eficiencia energética</li>
                        <li> Uso eficiente del agua</li>
                        <li> Eficiencia en materiales o reciclaje</li>
                        <li> Construcción sostenible</li>
                        <li> Producción agrícola sostenible</li>
                        <li> Turismo sostenible</li>
                      </h5>
                      <br />
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Requisitos:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        - Cédula de identidad y papeleta de votación.
                      </h5>
                      <h5 className="ps-4">- Edad de 18 – 75 años.</h5>
                      <h5 className="ps-4">
                        - Roles de pago mecanizado del IESS (En caso de ser
                        dependiente)
                      </h5>
                      <h5 className="ps-4">
                        - Justificación de Ingresos: Facturas/Notas de Venta,
                        Declaración del Impuesto a la Renta del año anterior –
                        Declaración del Iva, Certificado Comercial de
                        Proveedores, Contrato de Arriendo Notarizado, Patente,
                        Proforma de Inversion (En caso de ser independiente)
                      </h5>
                      <h5 className="ps-4">- Planilla de servicio básico.</h5>
                      <h5 className="ps-4">
                        - Impuesto predial (En caso de tenerlo).
                      </h5>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Tasa de Interés:
                        </h5>
                      </div>
                      <h5 className="ps-4">- Tasa de interés del 15 %</h5>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Características del crédito:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        - <strong>AGILIDAD:</strong> Tu crédito se aprueba en un
                        plazo de 24 a 48 horas.
                      </h5>
                      <h5 className="ps-4">
                        - <strong>FLEXIBILIDAD:</strong> Este crédito se puede
                        adaptar de acuerdo a tus necesidades.
                      </h5>
                      <h5 className="ps-4">
                        - <strong>BENEFICIOS:</strong> Seguro médico para el
                        deudor, cónyuge y 3 dependientes menores de 23 años,
                        acceso a nuestros servicios de odontología, peluquería y
                        un fondo mortuorio de hasta $5.000.
                      </h5>
                      <h5 className="ps-4">
                        - <strong>FACILIDAD:</strong> Puedes cancelar las cuotas
                        de tu crédito desde cualquier Agencia de la Coac. Futuro
                        Lamanense o transferencias electrónicas.
                      </h5>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">Beneficios:</h5>
                      </div>
                      <h5 className="ps-4">
                        Si pagas tus cuotas puntuales, participas en los sorteos
                        trimestrales
                      </h5>
                      <br />
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default EcoFuturo;