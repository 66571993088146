import React from "react";
import codigoetica from "../../imagenes/codigoetica.png";

class CodigoEtica extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1 className="mb-4">CÓDIGO DE ÉTICA</h1>
                <h2 className="mb-4">
                  Nuestro Código de Ética establece los principios fundamentales
                  que guían nuestras acciones y decisiones, promoviendo la
                  integridad, el respeto y la transparencia en todas nuestras
                  relaciones y actividades.
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={codigoetica}
                  className="img-fluid animated rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>
                  LA PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIAMIENTO DE DELITOS
                </h2>
              </div>
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <h5 className="texto-justificado">
                    ARTÍCULO 1. Los directivos, administradores y trabajadores
                    tienen la obligación moral y legal de generar e impulsar a
                    nivel institucional, de socios, clientes, corresponsales y
                    proveedores de la Cooperativa, una cultura y conocimiento de
                    la Ley Orgánica de Prevención, Detección y Erradicación del
                    Delito de Lavado de Activos y del Financiamiento de Delitos
                    y su Reglamento General; el Código Orgánico Monetario y
                    Financiero; el Código Orgánico Integral Penal y, demás
                    normativa propia de la prevención, detección y erradicación
                    del delito de lavado de activos y del financiamiento de
                    delitos.
                  </h5>
                  <br />
                  <h5 className="texto-justificado">
                    ARTÍCULO 2. Los directivos y administradores establecerán
                    políticas para la prevención, detección y erradicación del
                    delito de lavado de activos y del financiamiento de delitos.
                    Los directivos, administradores y trabajadores de la
                    Cooperativa deberán anteponer el cumplimiento de las normas
                    en materia de prevención, detección y erradicación del
                    delito de lavado de activos y del financiamiento de delitos,
                    al logro de las metas comerciales; siendo su obligación
                    priorizar la realización de negocios seguros para minimizar
                    los riesgos de la Entidad. Constituye obligación inexcusable
                    de directivos, administradores, trabajadores, socios,
                    clientes, corresponsales y proveedores de la Cooperativa
                    cumplir con la normativa y demás disposiciones relacionadas
                    con la prevención, detección y erradicación del delito de
                    lavado de activos y del financiamiento de delitos; su
                    inobservancia será considerado falta grave y dará lugar a la
                    aplicación de sanciones establecidas en la normativa interna
                    y externa pertinente, sin que ello extinga su
                    responsabilidad penal.
                  </h5>
                  <br />
                </div>
              </div>
              <br /> <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default CodigoEtica;
