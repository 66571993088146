import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApi;

async function callApi(endpoint, options) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiVisitas = {
    visitas: {

        create(data) {
            return callApi(`/visita`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
        },

    }
};

export default apiVisitas;