import React, { Component } from "react";
import EmptyPhoto from '../../../imagenes/noticias/svg/Empty-photo.svg';
import "../../../styles/Gallery.css";

// Import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import ImageComponent from "./ImageComponent";
import ModalComponent from "./ModalComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchApiNodeNoticies } from "../../../services/noticiasApi";
import urlGlobal from '../../../services/urlGlobal';
const { UrlGlobal } = urlGlobal;

class GaleriaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGallery: [],
      selectedImage: null,
    };
  }

  componentDidMount() {
    this.fetchGalleryData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id_notice !== prevProps.id_notice) {
      this.fetchGalleryData();
    }
  }

  fetchGalleryData = async () => {
    const { id_notice } = this.props;
    if (id_notice) {
      try {
        const params = { id_notice: id_notice };
        const galleryData = await fetchApiNodeNoticies(
          "POST",
          "get-gallery-notice",
          params
        );
        this.setState({ listGallery: galleryData.data || [] });
      } catch (error) {
        console.error("Error fetching galleryData:", error);
        this.setState({ listGallery: [] });
      }
    }
  };

  handleImageClick = (urlImage) => {
    this.setState({ selectedImage: urlImage });
  };

  handleCloseModal = () => {
    this.setState({ selectedImage: null });
  };

  render() {
    const { listGallery, selectedImage } = this.state;

    return (
      <>
        {listGallery.length > 0 ? (
          <div className={listGallery.length > 0 ? "box" : ""}>
            <Swiper
              style={{ height: 300 }}
              slidesPerView={2}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              spaceBetween={50}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {listGallery.map((item) => (
                <SwiperSlide key={item.id_gallery}>
                  <ImageComponent
                    urlImage={item.name_image ? `${UrlGlobal.urlImagenesNoticias}${item?.name_image}` : EmptyPhoto}
                    onClick={() => this.handleImageClick(item.name_image)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : null}
        {selectedImage && (
          <ModalComponent urlImage={selectedImage} onClose={this.handleCloseModal} />
        )}
      </>
    );
  }
}

export default GaleriaComponent;
