import React from "react";
import saras from "../../imagenes/saras.jpg";
import { Link } from "react-router-dom";

class Saras extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1 className="mb-4">
                  ¿Qué es el Sistema de Administración de Riesgo Ambiental y
                  Social (SARAS)?
                </h1>
                <h2 className="mb-4">
                  Es un enfoque que identifica, evalúa y gestiona riesgos
                  ambientales y sociales asociados a proyectos, promoviendo
                  prácticas sostenibles y responsables.
                </h2>

                <div className="d-flex justify-content-center justify-content-lg-start">
                  <a
                    href="https://futurolamanense.fin.ec/docs/ManualSaras_V2.0.pdf"
                    target="_blank"
                    className="btn-get-started"
                  >
                    Manual de Políticas SARAS
                  </a>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={saras}
                  className="img-fluid animated rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="section-title p-0">
                <h2>Lista de exclusión</h2>
              </div>
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <h5>
                    La Cooperativa de Ahorro y Crédito Futuro Lamanense se
                    compromete a promover el desarrollo sostenible y
                    responsable. Por ello, no financiará las actividades que se
                    encuentren en la siguiente lista de exclusión:
                  </h5>
                  <br />
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Cultivo de Tabaco:
                    </h5>
                  </div>
                  <h5 className="ps-4">- Cultivo de tabaco en bruto.</h5>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Destilación, rectificación y mezcla de bebidas
                      alcohólicas:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Elaboración de bebidas alcohólicas destiladas:{" "}
                    <strong>
                      whisky, coñac, brandy, ginebra, aguardiente de caña de
                      azúcar, etcétera.
                    </strong>
                  </h5>
                  <h5 className="ps-4">
                    - Elaboración de mezcla de bebidas alcohólicas destiladas y
                    preparados alcohólicos compuestos:{" "}
                    <strong>
                      cremas y otras bebidas alcohólicas aromatizadas o
                      azucaradas.
                    </strong>
                  </h5>
                  <h5 className="ps-4">
                    - Producción de aguardientes neutros (alcoholes base para
                    elaborar bebidas alcohólicas).
                  </h5>
                  <h5 className="ps-4">
                    - Servicios de apoyo a la destilación, rectificación y
                    mezcla de bebidas alcohólicas a cambio de una retribución o
                    por contrato.
                  </h5>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Elaboración de productos de tabaco:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Elaboración de productos de tabaco y sustitutos de
                    productos de tabaco:{" "}
                    <strong>
                      tabaco homogeneizado o reconstituido, cigarrillos,
                      picadura para cigarrillos, tabaco para pipa, tabaco de
                      mascar, rape, etcétera.
                    </strong>
                  </h5>
                  <h5 className="ps-4">
                    - Desnervado y secado de las hojas de tabaco.
                  </h5>
                  <h5 className="ps-4">
                    - Elaboración de extractos y esencias de tabaco.
                  </h5>
                  <h5 className="ps-4">
                    - Servicios de apoyo a la elaboración de productos de tabaco
                    a cambio de una retribución o por contrato.
                  </h5>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Fabricación de armas y municiones:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Fabricación de armas pesadas:{" "}
                    <strong>
                      artillería, cañones móviles, lanzacohetes, tubos
                      lanzatorpedos, ametralladoras pesadas, etcétera.
                    </strong>
                  </h5>
                  <h5 className="ps-4">
                    - Fabricación de armas ligeras:{" "}
                    <strong>
                      revólveres, escopetas, ametralladoras ligeras, etcétera.
                    </strong>
                  </h5>
                  <h5 className="ps-4">
                    - Fabricación de escopetas y pistolas de aire y gas
                    comprimido.
                  </h5>
                  <h5 className="ps-4">
                    - Fabricación de municiones de guerra.
                  </h5>
                  <h5 className="ps-4">
                    - Servicios de apoyo a la fabricación de armas y municiones
                    a cambio de una retribución o por contrato.
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Fabricación de vehículos militares de combate:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Fabricación de vehículos militares de combate:{" "}
                    <strong>
                      tanques de combate, vehículos anfibios blindados.
                    </strong>
                  </h5>
                  <h5 className="ps-4">
                    - Servicios de apoyo a la fabricación de vehículos militares
                    de combate a cambio de una retribución o por contrato.
                  </h5>
                  <h5 className="ps-4">
                    - Venta al por mayor de tabaco sin elaborar.
                  </h5>
                  <h5 className="ps-4">
                    - Venta al por mayor de bebidas alcohólicas, incluso
                    envasado de vino a granel sin trasformación.
                  </h5>
                  <h5 className="ps-4">
                    - Venta al por mayor de productos de tabaco.
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Actividades de juegos de azar y apuestas:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Gestión (explotación) de máquinas de juegos de azar
                    accionadas con monedas y explotación de casinos, incluidos
                    casinos flotantes, billares, etcétera.
                  </h5>
                  <h5 className="ps-4">
                    - Gestión de sitios de internet dedicados a los juegos de
                    azar virtuales, videojuegos.
                  </h5>
                  <h5 className="ps-4">
                    - Apuestas sobre carreras de caballos en el propio hipódromo
                    y fuera del hipódromo y otros servicios de apuestas.
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="section-title p-0">
                    <h2>¿Tienes Reclamos o Quejas?</h2>
                    <h5 class="mb-4">
                      Estamos aquí para ayudarte. Si tienes algún reclamo o
                      queja, por favor haz clic en el siguiente botón para más
                      información.
                    </h5>
                    <Link to="/quejas-reclamos-sugerencias" className="btnn">
                      Clic Aquí
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Saras;
