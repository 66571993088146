import React from "react";
import apiSucursal from "../../services/sucursalApi";
import ImageMatriz from "../../imagenes/matriz.png";

class Sucursales extends React.Component {

    state = {
        sucursales: [],
        loading: false,
        error: false
    };

    constructor(props, context) {
        super(props, context);
    }

    getSucursales = async () => {
        this.setState({ loading: true });
        try {
            const data = await apiSucursal.sucursal.getall();
            this.setState({ loading: false, error: false, sucursales: data.response });
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    }

    spinner = () => {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    Cargando...
                </div>
            );
        } else {
            return;
        }
    }

    componentDidMount() {
        this.getSucursales();
    }

    render() {
        return (
            <React.Fragment>
                {this.spinner()}
                <section className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>NUESTRAS SUCURSALES</h2>
                        </div>

                        <div className="row content">

                            <div className="col-lg-4 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                                <img src={ImageMatriz} className="img-fluid " alt="" />
                            </div>

                            <div className="col-lg-8 ">
                                <div className="row content">
                                    {
                                        this.state.sucursales.map((sucursal) => {
                                            return (
                                                <div className="col-lg-6 pb-4" key={sucursal.idsucursal}>
                                                    <h4><a >{sucursal.nombre}</a></h4>
                                                    <p>
                                                        <b>Dirección: </b> {sucursal.direccion}
                                                    </p>
                                                    <p>
                                                        <b>Teléfono: </b> {sucursal.telefono}
                                                    </p>
                                                    <p>
                                                        <b>Email: </b> info@futurolamanense.fin.ec
                                                    </p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}
export default Sucursales;