import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import AhorroInversion from "./../../imagenes/ahorroinversion.jpeg";
import AhorroJunior from "./../../imagenes/ahorrojunior.jpeg";
import AhorroProgramado from "./../../imagenes/ahorroprogrmado.jpeg";
import AhorroInclusion from "./../../imagenes/ahorroinclusion.jpg";
import AhorroInclusionJunior from "./../../imagenes/inclusion-junior.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider-swiper.css";

// Datos de productos de ahorro
const productosAhorro = [
  {
    title: "Ahorro Inversión",
    image: AhorroInversion,
    description:
      "Te ofrecemos tasas de interés competitivas con pagos mensuales de interés o al vencimiento.",
    link: "/ahorro-inversion",
    delay: 100,
  },
  {
    title: "Ahorro Junior",
    image: AhorroJunior,
    description:
      "Es una cuenta de ahorro a la vista para las personas menores de 16 años, destinada a fomentar la cultura del ahorro.",
    link: "/ahorro-junior",
    delay: 200,
  },
  {
    title: "Ahorro Programado",
    image: AhorroProgramado,
    description:
      "Estos valores ahorrados mensualmente permanecerán bloqueados en su cuenta de ahorros hasta el final del plazo fijado.",
    link: "/ahorro-programado",
    delay: 300,
  },
  {
    title: "Ahorro Inclusión",
    image: AhorroInclusion,
    description:
      "Un producto financiero revolucionario que abre las puertas al ahorro para todos, empoderando a jóvenes, adultos mayores y comunidades desatendidas.",
    link: "/ahorro-inclusion",
    delay: 400,
  },
  {
    title: "Ahorro Inclusión Junior",
    image: AhorroInclusionJunior,
    description:
      "¡Ahorra desde pequeño! Inclusión Junior fomenta el ahorro y la planificación financiera para niños, jóvenes, personas discapacitadas y mujeres embarazadas.",
    link: "/ahorro-inclusion-junior",
    delay: 500,
  },
];

class Ahorroproductos extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Ahorra y Piensa en tu Futuro</h2>
              <p>
                Comienza a ahorrar tu dinero de forma segura con nosotros. Te
                invitamos a que descubras los productos que tenemos para ti
                según tus necesidades de ahorro:
              </p>
            </div>

            <Swiper
              style={{ width: "90%" }}
              slidesPerView={1}
              slidesPerGroupSkip={1}
              spaceBetween={50}
              centeredSlides={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {productosAhorro.map((producto, index) => (
                <SwiperSlide key={index} className="slide">
                  <div
                    className="slide-item"
                    data-aos="zoom-in"
                    data-aos-delay={producto.delay}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img
                          src={producto.image}
                          className="w-50 rounded"
                          alt={producto.title}
                        />
                      </div>
                      <h4>
                        <Link to={producto.link}>{producto.title}</Link>
                      </h4>
                      <p className="texto-justificado">
                        {producto.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Ahorroproductos;
