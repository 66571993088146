export default class Mensajes {
    constructor(
        idmensajes,
        nombres,
        email,
        asunto,
        mensaje
    ) {
        this.idmensajes = idmensajes;
        this.nombres = nombres;
        this.email = email;
        this.asunto = asunto;
        this.mensaje = mensaje;
    }
}