import React from "react";
import OdontologiaImg from "../imagenes/educacion-financiera.png";
import apiVisitas from "../services/visitasApi";
import resumenEducacionFinancieraImage from "../imagenes/educacionfinanciera/resumeneducacionfinancierarecuperado.png";
import resumenMoneyWeekImage from "../imagenes/educacionfinanciera/resumenmoneyweek.png";

class EducacionFinanciera extends React.Component {

    agregarVisitaEducacionFinanciera = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Educación Financiera" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaEducacionFinanciera();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Educación Financiera</h1>
                                <h2>Coac Futuro Lamanense tiene para ti y tu familia cursos de educación financiera, con el fin de desarrollar habilidades útiles en el día a día para mejorar tus finanzas y tu bienestar</h2>
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <a href="https://matriculas.figlac.org/futuro-lamanense/" target="_blank" className="btn-get-started scrollto">Inscríbete Aquí</a>
                                    <a href="https://campus.figlac.org/course/view.php?id=11" target="_blank" className="glightbox btn-watch-video"><i class="bi bi-arrow-up-right-square"></i><span>Acceder a la Aula Virtual</span></a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={OdontologiaImg} className="image-educuacion-financiera rounded" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br />
                        <div className="container" data-aos="fade-up">
                            <br /> <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Cursos en:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Ahorro e Inversión
                                    </h5>
                                    <h5 className="ps-4">
                                        - Planificación y presupuesto familiar
                                    </h5>
                                    <h5 className="ps-4">
                                        - Endeudamiento responsable
                                    </h5>
                                    <h5 className="ps-4">
                                        - Productos y Servicios Financieros
                                    </h5>
                                    <h5 className="ps-4">
                                        - Cooperativismo con identidad
                                    </h5>
                                    <h5 className="ps-4">
                                        - Alcanza tu sueño
                                    </h5>
                                    <h5 className="ps-4">
                                        - Seguro de depósito Cosede
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-6 text-center">
                                    <img src={resumenEducacionFinancieraImage} className="img-fluid animated rounded w-100" alt="" />
                                </div>
                                <div className="col-lg-6 text-center">
                                    <img src={resumenMoneyWeekImage} className="img-fluid animated rounded w-100" alt="" />
                                </div>
                            </div>
                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default EducacionFinanciera;