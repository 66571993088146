import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";

class Gerencia extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column  pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <div className="text-center">
                                    <h1>GERENCIA</h1>
                                </div>
                                <div className="text-center pt-0">
                                    <img src={logoVertical} className="logovertical-gerencia" />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                {
                                    /*
                                    <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="text-center">
                                        <img src={gerencia} className="w-75 rounded" ></img>
                                        <h1 className="mt-3 text-success">MSc. CPA. Fabián Morán</h1>
                                        <h4 className="text-success">Gerente General</h4>
                                    </div>
                                </div>
                                    */
                                }

                                <div className="col-xl-1"></div>

                                <div className="col-xl-10 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                    <h2 className="texto-justificado pt-4">
                                        Optimizamos la solidez financiera, gobernabilidad, oportuna y calidad de servicio que entrega la cooperativa, con el objetivo de mejorar los indicadores financieros y gestión; así como los niveles de crecimiento y posicionamiento en el mercado, con la finalidad de consolidar y fortalecer la posición financiera y patrimonial de la cooperativa a través de un crecimiento sostenido de sus principales indicadores financieros.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default Gerencia;