import React from "react";
import apiVisitas from "../../services/visitasApi";

class AhorroInclusionJunior extends React.Component {
  agregarVisitaAhorroInclusionJunior = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Ahorro Inclusión Junior" });
    } catch (error) {
      console.log("error al crear visíta " + error);
    }
  };

  componentDidMount() {
    this.agregarVisitaAhorroInclusionJunior();
  }

  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Ahorro Inclusión Junior</h1>
                <h2>
                  Inclusión Junior es la cuenta de ahorro ideal para niños,
                  jóvenes, personas con discapacidad y mujeres embarazadas que
                  reciben el bono del Miess. Fomenta el ahorro y la
                  planificación financiera desde temprana edad.
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                {/* <img
                  src={AhorroJuniorImg}
                  className="image-cuenta-ahorros-junior rounded "
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Monto de apertura:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    El monto para la apertura de la cuenta es de USD $20.00
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos:
                    </h5>
                  </div>
                  <h5 className="ps-4">- Edad de 0 - 17 años</h5>
                  <h5 className="ps-4">
                    - Copia a color de cédula del menor
                  </h5>
                  <h5 className="ps-4">
                    - Copia a color de la cédula y la papeleta de votación del representante
                  </h5>
                  <h5 className="ps-4">
                    - Copia de la última planilla de cualquier servicio básico
                  </h5>
                  <h5 className="ps-4">- Correo Electrónico</h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Beneficios:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Peluquería, Odontología y Medicina General
                  </h5>
                  <h5 className="ps-4">- Acceso a la Banca web y móvil </h5>
                  <h5 className="ps-4">- Participa en sorteos trimestrales</h5>
                  <br />
                </div>
              </div>
              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default AhorroInclusionJunior;
