import React from "react";
import CreditoFuturoImg from "../../imagenes/plazofijo.png";
import apiVisitas from "../../services/visitasApi";
import apiSimulador from "../../services/simuladorApi";

class DepositoPlazoFijo extends React.Component {

    state = {
        form: {
            frecuencia: 'MENSUAL',
            plazo: '',
            monto: '',
        },
        dataRespuestaCalculo: {},
        estadoCalculo: 'vacio'
    };

    agregarVisitaPlazoFijo = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Depósito a Plazo Fijo" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    componentDidMount() {
        this.agregarVisitaPlazoFijo();
    }

    calcularInversion = async () => {
        try {
            var dataConsulta = { 'plazo': +this.state.form.plazo, 'monto': +this.state.form.monto, 'tipoplazo': this.state.form.frecuencia }
            const data = await apiSimulador.simulador.inversion(dataConsulta)
            this.setState({ dataRespuestaCalculo: data, estadoCalculo: 'consultado' });
        } catch (error) {
            console.log(error);
            this.setState({ estadoCalculo: 'error' });
        }
    }

    tipoFrecuenciaM = () => {
        if (this.state.form.frecuencia == 'MENSUAL') {
            return (
                <span>Meses</span>
            );
        } else {
            return (
                <span>Días</span>
            );
        }
    }

    tipoFrecuenciaM2 = () => {
        if (this.state.form.frecuencia == 'MENSUAL') {
            return (
                <span>el mes</span>
            );
        } else {
            return (
                <div>en el día</div>
            );
        }
    }

    limpiar = () => {
        this.setState({ estadoCalculo: 'vacio' });
    }

    respuestaCalculoInversion = () => {
        if (this.state.estadoCalculo == 'consultado') {
            return (
                <div className="border-top mt-3 pt-3">
                    <table className="table table-border">
                        <tbody>
                            <tr >
                                <th className="bgfila" scope="row">Monto </th>
                                <td className="bgfila" >${this.state.form.monto}</td>
                                <td></td>
                                <th className="bgfila" scope="row">Plazo en {this.tipoFrecuenciaM()} </th>
                                <td className="bgfila">{this.state.form.plazo}</td>
                            </tr>
                            <tr>
                                <th className="bgfila" scope="row">Tasa de interés</th>
                                <td className="bgfila" >{this.state.dataRespuestaCalculo.tasainteres}%</td>
                                <td></td>
                                <th className="bgfila" scope="row">Interés en {this.tipoFrecuenciaM2()} </th>
                                <td className="bgfila">${this.state.dataRespuestaCalculo.interesMensualDiario}</td>
                            </tr>
                            <tr>
                                <th className="bgfila" scope="row">Total interés</th>
                                <td className="bgfila">${this.state.dataRespuestaCalculo.total}</td>
                                <td></td>
                                <th className="bgfila" scope="row">Total a recibir</th>
                                <td className="bgfila">${this.state.dataRespuestaCalculo.suma}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else if (this.state.estadoCalculo == 'error') {
            return (
                <div className="text-center p-5">
                    <h1 className="text-danger">Error al calcular, intente más tarde</h1>
                </div>
            );
        }
    }


    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Depósito a Plazo Fijo</h1>
                                <h2>¡Invierte con nosotros!  Obtén la mejor rentabilidad de tu capital invirtiendo desde 100$ al plazo que tu elijas.</h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={CreditoFuturoImg} className="image-cuenta-ahorros-inversion rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                        Con tus DEPÓSITOS A PLAZO FIJO puedes aumentar tu dinero de forma segura, obteniendo excelente rentabilidad. Pagamos las tasas de interés más altas del mercado.
                                    </h5>
                                    <br />
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Características
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Forme parte del selecto portafolio de inversionistas que confían en la Cooperativa y gane con las tasas de interés mas altas del mercado.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Mientras más largo es el tiempo de su inversión, más altos son los intereses a su favor.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Ventajas
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Acreditación en la cuenta de ahorros.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Disponibilidad de dinero en el día del vencimiento.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Puede acceder a créditos en mejores condiciones.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Excelentes tasas de interés del mercado
                                    </h5>

                                    <h5 className="ps-4">
                                        - Recibes Obsequios y Participas en los sorteos Semestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Simulador
                                        </h5>
                                    </div>
                                    <div className="mt-4 bg-white p-4 rounded">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label className="ps-1">Frecuencia</label>
                                                    <select className="form-control" onChange={(e) => { this.limpiar(); this.handleChange(e) }} name="frecuencia" value={this.state.form.frecuencia} required>
                                                        <option value="MENSUAL">MENSUAL</option>
                                                        <option value="DIARIO">DIARIO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label className="ps-1">Plazo en {this.tipoFrecuenciaM()}</label>
                                                    <input className="form-control" type="number" onChange={this.handleChange} name="plazo" value={this.state.form.plazo} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label className="ps-1">Monto</label>
                                                    <input className="form-control" type="text" onChange={this.handleChange} name="monto" value={this.state.form.monto} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 pt-1">
                                                <button className="mt-3 btnn" onClick={() => this.calcularInversion()}>
                                                    Calcular
                                                </button>
                                            </div>
                                        </div>
                                        <br />
                                        {this.respuestaCalculoInversion()}
                                    </div>
                                    <br />
                                </div>
                            </div> 
                            <br />
                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default DepositoPlazoFijo;