import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";

const directiva = [
  { name: "CPA. Moran Marmolejo Fabian Medardo", position: "Gerente" },
  { name: "Lic. Jácome Calderon Alba Verónica", position: "Presidente" },
  { name: "Abg. Escobar Chango Guido Fabian", position: "Vicepresidente" },
  { name: "Abg. Jaramillo Medina Enrique Orlando", position: "Secretario" },
];

const consejoAdministracion = [
  { name: "Jácome Calderon Alba Verónica", position: "Vocal Principal 1" },
  { name: "Escobar Chango Guido Fabian", position: "Vocal Principal 2" },
  { name: "Lcda. Palma Bravo Nelly Janeth", position: "Vocal Principal 3" },
  {
    name: "Ing. Delgado Rodríguez Hugo Bolivar",
    position: "Vocal Principal 4",
  },
  { name: "Lcda. Loor Valencia Ramona Yannyne", position: "Vocal Principal 5" },
  { name: "Dr. Guzman Simanca Jesus Manuel", position: "Vocal Principal 6" },
  {
    name: "Ing. Romero Calderon Michelle Virginia",
    position: "Vocal Principal 7",
  },
  { name: "Sr. Rosado Sosa Daniel Laureno", position: "Vocal Principal 8" },
  { name: "Sra. Armas Vela Elvia Juliana", position: "Vocal Principal 9" },
  { name: "Montes Macias Galo Aristobal", position: "Vocal Suplente 1" },
  { name: "Ayala Carriel Gaudencio Vincente", position: "Vocal Suplente 2" },
  { name: "Villegas Rodriguez Laura Elizabeth", position: "Vocal Suplente 3" },
  {
    name: "Chanaluisa Yanchapaxi Piedad de las Mercedes",
    position: "Vocal Suplente 4",
  },
  { name: "Navarrete Boharquez Adalaida Angela", position: "Vocal Suplente 5" },
  { name: "Zavala Marcillo Mercedes Benita", position: "Vocal Suplente 6" },
  { name: "Saavedra Velez Carlos David", position: "Vocal Suplente 7" },
  { name: "Moreno Reyes Martha Jenny", position: "Vocal Suplente 8" },
];

const consejoVigilancia = [
  { name: "Palomo Caisa Beatriz Elsa", position: "Vocal Principal 1" },
  { name: "Cabrera Rayna Alex Rodolfo", position: "Vocal Principal 2" },
  { name: "Albarracin Cepeda Laura Fernanda", position: "Vocal Principal 3" },
  { name: "Torres Velez Erwin Santiago", position: "Vocal Principal 4" },
  { name: "Chango Guaman Carmen Piedad", position: "Vocal Principal 5" },
  { name: "Espinoza Conforme Jesus Vicente", position: "Vocal Principal 6" },
  { name: "Paz Maldonado Mery Elizabeth", position: "Vocal Principal 7" },
  { name: "Ruiz Mena Pedro Fabian", position: "Vocal Principal 8" },
  { name: "Cedeño Troya Dolores Magaly", position: "Vocal Principal 9" },
  {
    name: "Peñaherrara Tovar Rosario Guadalupe",
    position: "Vocal Principal 10",
  },
];

const comites = [
  {
    title: "Comité De Administración Integral De Riesgos",
    description:
      "Es un órgano asesor del Consejo de Administración, responsable del diseño de políticas, procesos, estrategias, metodologías, sistemas de información y procedimientos para la eficiente gestión integral de riesgos.",
  },
  {
    title: "Comité De Cumplimiento",
    description:
      "Es el encargado de velar por la aplicación de las políticas y procedimientos de control para mitigar el riesgo de Lavado de Activos y Financiamiento de Delitos incluido el Terrorismo.",
  },
  {
    title: "Comité De Conducta Y Ética",
    description:
      "Es el encargado de garantizar que los objetivos del uso de tecnología estén alineados a los objetivos generales de la cooperativa, complementariamente debe velar la ejecución de los procesos para evitar el riesgo de retrasos o incumplimientos que no permitan alcanzar los objetivos institucionales.",
  },
  {
    title: "Comité De Conducta Y Ética",
    description:
      "Es el encargado de preparar y establecer procedimientos para evitar conflictos de interés, aplicar las sanciones ante los incumplimientos de principios y deberes, a fin de promover el comportamiento de excelencia profesional y personal dentro de la cooperativa.",
  },
  {
    title: "Comité De Gobernanza",
    description:
      "Es el encargado de realizar la planeación, gestión, ejecución y monitoreo de la gobernanza en la cooperativa, así como también adoptar las acciones necesarias para la gobernanza efectiva de la entidad, por lo que recomienda al Consejo de Administración las estrategias para la mejora de las prácticas de un buen gobierno corporativo.",
  },
  {
    title: "Comité De Cumplimiento",
    description:
      "Son aquellos que están conformados de acuerdo a los requerimientos de control interno propios de la cooperativa, así como también aquellos que están establecidos por disposiciones normativas, siendo los siguientes: Comisión de Crédito, Comisión de Educación y Cultura, Comisión de Asuntos Sociales y Deporte, Comisión de Licitación y Adquisiciones.",
  },
];

class GobiernoCorporativo extends React.Component {
  renderTable(data) {
    return (
      <table className="table table-bordered">
        <thead>
          <tr className="table-primary">
            <th scope="col" className="text-center">
              APELLIDOS Y NOMBRES
            </th>
            <th scope="col" className="text-center">
              CARGO
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((member, index) => (
            <tr key={index}>
              <td className="text-center">{member.name}</td>
              <td className="text-center">{member.position}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderCommittees(data) {
    return data.map((comite, index) => (
      <div
        className="col-xl-6 col-md-6 mb-3 d-flex align-items-stretch"
        key={index}
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="icon-box">
          <h4>
            <a>{comite.title}</a>
          </h4>
          <h5 className="texto-justificado">{comite.description}</h5>
        </div>
      </div>
    ));
  }

  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 d-flex flex-column pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="text-center">
                  <h1>GOBIERNO CORPORATIVO</h1>
                </div>
                <div className="text-center pt-0">
                  <img
                    src={logoVertical}
                    className="logovertical-gerencia"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <h5 className="texto-justificado">
                    Es el sistema por el cual una organización toma e implementa
                    decisiones para lograr sus objetivos. Consiste en la
                    definición de la estructura y funcionamiento de la
                    administración de la organización para, definir derechos y
                    deberes exigibles a los administradores y establecer los
                    mecanismos de protección de los derechos de los socios.
                  </h5>
                  <br />
                  <h5 className="texto-justificado">
                    Nuestra estructura de Gobierno Corporativo está diseñada
                    acorde a lo que dispone La Ley de Economía Popular y
                    Solidaria, lo que permite articular planes estratégicos,
                    políticas corporativas y mecanismos de control y
                    transparencia bien estructurados, entre todas las instancias
                    de la Cooperativa.
                  </h5>
                  <br />
                  <h5 className="texto-justificado">
                    La buena gestión de Gobierno Corporativo nos ha permitido
                    obtener certificaciones que reconocen la mejora en la
                    calidad de gobernanza. Entre ellas están: Certificación GIF
                    Gobernanza e inclusión.
                  </h5>
                </div>
              </div>
              <br /> <br />
              <h5 className="fw-bold">DIRECTIVA</h5>
              {this.renderTable(directiva)}
              <br /> <br />
              <h5 className="fw-bold">NÓMINA CONSEJO DE ADMINISTRACIÓN</h5>
              {this.renderTable(consejoAdministracion)}
              <br /> <br />
              <h5 className="fw-bold">NÓMINA CONSEJO DE VIGILANCIA</h5>
              {this.renderTable(consejoVigilancia)}
            </div>
            <br /> <br /> <br /> <br />
          </section>

          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Comités por Normativa</h2>
              </div>
              <div className="row">{this.renderCommittees(comites)}</div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default GobiernoCorporativo;
