import React from "react";
import Recomiendapng from "../../imagenes/recomiendagana.png";
import apiVerificarUsuarioApi from "../../services/verificarusuarioApi";
import Logo from "../../imagenes/logosnormales/logohorizont.svg"
import apiSorteos from "../../services/sorteosApi";
import Global from "../../services/urlGlobal";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

class RecomiendayGana extends React.Component {

    state = {
        datausuario: {},
        loading: false,
        error: false,
        form: {
            'buscadortxt': '',
        },
        sorteoAlterno: {},
        sorteoGeneral: {},
        existeCliente: 'vacio',
    };

    getSorteos = async () => {
        this.setState({ loading: true });
        try {
            const data = await apiSorteos.sorteo.getall();
            if (data.response[0]) {
                this.setState({ sorteoGeneral: data.response[0], sorteoAlterno: data.response[1] });
            }
        } catch (error) {
            console.log(error);
        }
    }

    buscarUsuarios = async () => {
        this.setState({ loading: true });
        try {
            const data = await apiVerificarUsuarioApi.verificar.siexiste(this.state.form.buscadortxt);
            if (data.response) {
                this.setState({ existeCliente: 'existe', datausuario: data.response });
            } else {
                this.setState({ existeCliente: 'noexiste', datausuario: null });
            }
            console.log(data)

        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    }

    spinner = () => {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    Cargando...
                </div>
            );
        } else {
            return;
        }
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    componentDidMount() {
        this.getSorteos();
    }

    copiarNumeroTicket = (numero) => {
        navigator.clipboard.writeText(numero)
        toast.success('Ticket N°: ' + numero + ' copiado!!');
    }

    ticketshow = () => {
        if (this.state.existeCliente == 'existe') {
            return (
                <div className="icon-box">

                    <div className="borde-ticket p-2 text-center">
                        <div className="cabecera-ticket pt-1 pb-1 rounded">

                            <img src={Logo} className="w-50" />
                        </div>
                        <h5 className="mt-3">{this.state.datausuario.nombres} {this.state.datausuario.apellidos}</h5>
                        <h4 className="text-secondary mt-4"><b>Ticket de recomendación</b></h4>
                        <h2 className="mt-4 mb-2">{this.state.datausuario.secuencialPersona} </h2>
                        <p><button className="btn btn-outline-dark" onClick={() => this.copiarNumeroTicket(this.state.datausuario.secuencialPersona)}>Copiar número de ticket</button></p>
                        <br />
                        <h5 className="text-secondary">
                            Comparte tu número de ticket a tus amigos, por cada 3 cuentas aperturadas o 3 solicitudes de crédito con el número de tu ticket recibiras un premio y además se genera un cupón para participar en los sorteos trimestrales:
                        </h5>
                        <br />
                        <img src={Global.UrlGlobal.urlArchivos + this.state.sorteoAlterno.imagen} className="w-75 rounded" />
                    </div>
                </div>
            );
        } else if (this.state.existeCliente == 'noexiste') {
            return (
                <div className="icon-box">
                    <h4 className="text-warning">
                        ¡No eres socio de la cooperativa!
                    </h4>
                    <h5 className="texto-justificado">
                        Apertura una <Link to={"/cuenta-ahorros"}>cuenta de ahorros</Link> en cualquiera de nuestras <Link to={"/agencias"}>oficinas</Link> y automáticamente participas en el sorteo de:
                    </h5>
                    <br />
                    <div className="borde-ticket p-3 text-center">
                        <br />
                        <h4>{this.state.sorteoAlterno.premio}</h4>
                        <br />
                        <img src={Global.UrlGlobal.urlArchivos + this.state.sorteoAlterno.imagen} className="w-75 rounded" />
                    </div>
                    <br />
                </div>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Recomienda y gana</h1>
                                <h2>Por cada recomendación que hagas, entras al sorteo de una camiseta de la selección.</h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                                <img src={Recomiendapng} className="img-fluid animated rounded w-75 " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Genera tu ticket de recomendación
                                        </h5>
                                    </div>
                                    <div className="container">
                                        <br />
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="ps-1">Identificación:</label>
                                                <input className="form-control" onChange={this.handleChange} name="buscadortxt" value={this.state.form.buscadortxt} required />

                                                <div className="text-end mt-2">
                                                    <button className=" btnn" onClick={() => this.buscarUsuarios()}>
                                                        Generar Ticket
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-6">
                                                {this.ticketshow()}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default RecomiendayGana;