import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImageComponent from './ImageComponent';
import '../../../styles/Card.css';
import urlGlobal from '../../../services/urlGlobal';
import EmptyPhoto from '../../../imagenes/noticias/svg/Empty-photo.svg';

// Extraer las variables de urlGlobal
const { UrlGlobal, formatDateTime } = urlGlobal;

class CardComponent extends Component {
  render() {
    const {
      id_notice,
      title,
      img_card,
      date_time,
      description,
      onClick,
      idCateg,
      nameCateg,
    } = this.props;

    return (
      <div className="col-xl-4 col-md-6 col-sm-12 d-flex justify-content-evenly align-items-stretch pb-3">
        <div className="card" key={id_notice}>
          <div className="m-2">
            <ImageComponent
              urlImage={img_card ? `${UrlGlobal.urlImagenesNoticias}${img_card}` : EmptyPhoto}
              styleCard={true}
              objectFit="fill"
            />
          </div>
          <div className="card-body lh-lg">
            <h5 className="card-title card-text-justify">{title}</h5>
            <div>
              <span className="badge rounded-pill fs-7" style={{backgroundColor: "#77B735"}}>
                {nameCateg}
              </span>
            </div>
            <div>
              <strong>Fecha: </strong>
              {formatDateTime(date_time)}
            </div>
            {/* Contenido de la descripción */}
            <div className="card-text card-text-justify card-limit-text" dangerouslySetInnerHTML={{ __html: description }}>
            </div>
            <Link
              to={`/noticias/${nameCateg}/${idCateg}/${id_notice}`}
              className="btn btn-bg-color w-100"
              onClick={onClick}
            >
              <span className="fw-bold">Ver más</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CardComponent;
