import React, { Component } from "react";
import ImageComponent from "./ImageComponent";

class EmptyImageComponent extends Component {
  render() {
    const { srcImage, description } = this.props;

    return (
      <div className="my-3 w-auto">
        <ImageComponent urlImage={srcImage} widthImg={300} />
        <div className="text-center">
          <span className="text-secondary">{description}</span>
        </div>
      </div>
    );
  }
}

export default EmptyImageComponent;
