import React from "react";
import cursosvacacionalesDesktop from './../../imagenes/baners-paginas/cursosvacacionales.png';
import robotica from './../../imagenes/cursosvacacionales/robotica.jpg';
import musica from './../../imagenes/cursosvacacionales/musica.jpg';
import dibujo from './../../imagenes/cursosvacacionales/dibujo.jpg';
import danza from './../../imagenes/cursosvacacionales/danza.jpg';

class CursosVacacionales extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container ">
                        <div className="row">
                            <div className=" col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>CURSOS VACACIONALES</h1>
                                <h2 className="mb-3">
                                    PARA NUESTROS CUENTA AHORRISTAS JR
                                </h2>
                                <h2>
                                    Inicio de clases: 19 de febrero.
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={cursosvacacionalesDesktop} className="img-fluid rounded image-credito-violeta " />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Nuestros cursos:
                                        </h5>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 text-center">
                                            <img src={danza} className="img-fluid rounded w-75 mb-5" />

                                        </div>
                                        <div className="col-lg-6 text-center">

                                            <img src={dibujo} className="img-fluid rounded w-75 mb-5" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 text-center">
                                            <img src={musica} className="img-fluid rounded w-75 mb-5" />
                                        </div>
                                        <div className="col-lg-6 text-center">
                                            <img src={robotica} className="img-fluid rounded w-75 mb-5" />
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default CursosVacacionales;