import React from "react";
import creditofutuaniversario from './../../../imagenes/baners-paginas/futuaniversario.png';

class CreditoFutuAniversario extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container ">
                        <div className="row">
                            <div className=" col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>CRÉDITO FUTUANIVERSARIO</h1>
                                <h2 className="mb-3">
                                    Crédito válido por el mes de marzo.
                                </h2>
                                <h2>
                                    Monto máximo de $10.000, hasta 36 meses plazo
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={creditofutuaniversario} className="img-fluid rounded image-credito-violeta " />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                        Un crédito válido por el mes de marzo. Aplican restricciones
                                    </h5>
                                    <br />
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Cédula de identidad y papeleta de votación.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Edad de 18 – 75 años.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Roles de pago mecanizado del IESS (En caso de ser dependiente)
                                    </h5>
                                    <h5 className="ps-4">
                                        - Justificación de Ingresos: Facturas/Notas de Venta, Declaración del Impuesto a la Renta del año anterior – Declaración del Iva, Certificado Comercial de Proveedores, Contrato de Arriendo Notarizado, Patente, Proforma de Inversion (En caso de ser independiente)
                                    </h5>
                                    <h5 className="ps-4">
                                        - Planilla de servicio básico.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Impuesto predial (En caso de tenerlo).
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Tasa de Interés
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Tasa de interés del 14 %
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Características del crédito:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - AGILIDAD: Tu crédito se aprueba en un plazo de  24 a 48 horas.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FLEXIBILIDAD: Este crédito se puede adaptar de acuerdo a tus necesidades.
                                    </h5>
                                    <h5 className="ps-4">
                                        - BENEFICIOS: Seguro médico para el deudor, cónyuge y 3 dependientes menores de 23 años, acceso a nuestros servicios de odontología, peluquería y un fondo mortuorio de hasta $5.000.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FACILIDAD: Puedes cancelar las cuotas de tu crédito desde cualquier Agencia de la Coac. Futuro Lamanense o transferencias electrónicas.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Si pagas tus cuotas puntuales, participas en los sorteos trimestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                        <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default CreditoFutuAniversario;