import Sorteo from "../models/Sorteo";
import Global from "./urlGlobal";
const BASEE = Global.UrlGlobal.urlApi;

async function callApidatos(endpoint, options) {
    const url = BASEE + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiSorteos = {
    sorteo: {

        getall() {
            return callApidatos(`/sorteos-all`);
        },

    }
};

export default apiSorteos;