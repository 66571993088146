import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApiGolang;

async function callApi(endpoint) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url);
    const data = await response.json();

    return data;
}

const verificarUsuarioApi = {
    verificar: {
        siexiste(identificacion) {
            return callApi(`/get-cliente-si-existe/` + identificacion);
        }
    }
};

export default verificarUsuarioApi;