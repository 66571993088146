import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApi;

async function callApi(endpoint) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url);
    const data = await response.json();

    return data;
}

const apiSucursal = {
    sucursal: {
        getall() {
            return callApi(`/sucursales`);
        }
    }
};

export default apiSucursal;