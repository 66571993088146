import React from "react";
import apiSimulador from "../../../services/simuladorApi";
import urlGlobal from "../../../services/urlGlobal";
import SweetAlert2 from "react-sweetalert2";

class SimuladorCreditoVioleta extends React.Component {

    state = {
        subcalificacion: [],
        form: {
            'segmentointerno': '7',
            'subcalificacion': '',
            'monto': '',
            'diafijo': '',
            'cuotas': '',
        },
        swal: {},
        loading: 'false',
        tablapresuntiva: [],
        resultcredito: {
            FechaAdjudicacion: '',
            FechaVencimiento: '',
            Tea: '',
            ValorTasa: '',
            DescripcionCondicionTablaAmortizacion: ''
        },
        calculo: 'false'
    }

    componentDidMount() {
        this.getSubcalificacion(7)
    }

    limpiar = () => {
        this.setState({
            form: {
                'segmentointerno': '',
                'subcalificacion': '',
                'monto': '',
                'diafijo': '',
                'cuotas': '',
            },
            loading: 'false',
            tablapresuntiva: [],
            resultcredito: {
                FechaAdjudicacion: '',
                FechaVencimiento: '',
                Tea: '',
                ValorTasa: '',
                DescripcionCondicionTablaAmortizacion: '',
                DescripcionTipoPrestamo: ''
            },
            calculo: 'false',
            subcalificacion: []
        })
    }

    cerrarSwal = () => {
        setTimeout(() => {
            this.setState({
                swal: {
                    show: false,
                },
            });
        }, 3000)
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    getSubcalificacion = async (secuencial) => {
        try {
            const data = await apiSimulador.simulador.subcalificacion(secuencial);
            console.log(data)
            this.setState({ subcalificacion: data.subcalificacion });
        } catch (error) {
            console.log(error);
        }
    }

    consultarCredito = async () => {
        if (this.state.form.subcalificacion === '' || this.state.form.monto === '' || this.state.form.diafijo === '' || this.state.form.cuotas === '') {
            this.setState({
                swal: {
                    show: true,
                    title: 'Error',
                    text: "Tienes que llenar todos los campos",
                    icon: 'error',
                },
            });
            this.cerrarSwal();
        } else {
            this.setState({ loading: 'true' });
            try {
                const data = await apiSimulador.simulador.consultarCredito('015', '1', '7', this.state.form.subcalificacion, urlGlobal.fechaActual(), this.state.form.monto, this.state.form.diafijo, this.state.form.cuotas, 30);
                this.setState(
                    {
                        loading: 'false',
                        tablapresuntiva: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla.TablaPagos_PorCuotas,
                        resultcredito: {
                            FechaAdjudicacion: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.FechaAdjudicacion,
                            FechaVencimiento: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.FechaVencimiento,
                            Tea: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.Tea,
                            ValorTasa: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ValorTasa,
                            DescripcionCondicionTablaAmortizacion: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.DescripcionCondicionTablaAmortizacion,
                            DescripcionTipoPrestamo: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.DescripcionTipoPrestamo
                        },
                        calculo: 'true'
                    });
            } catch (error) {
                console.log(error);
                this.setState({ loading: 'false' });
            }
        }
    }

    mostrarCalculo = () => {
        if (this.state.calculo === 'true') {
            return (
                <>

                    <div className="bg-white p-2 rounded">
                        <br />
                        <div className="text-center">
                            <h4>TABLA PRESUNTIVA DE PAGOS</h4>
                        </div>
                        <br />
                        <div className="row font-letter">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Tipo de prestamo:</b> </div>
                                    <div className="col-lg-6"> {this.state.resultcredito.DescripcionTipoPrestamo} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>No. cuotas:</b> </div>
                                    <div className="col-lg-6"> {this.state.form.cuotas} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Frecuencia de pago:</b> </div>
                                    <div className="col-lg-6"> 30 </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Monto solicitado:</b> </div>
                                    <div className="col-lg-6"> ${this.state.form.monto} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Valor Tasa:</b> </div>
                                    <div className="col-lg-6"> ${this.state.resultcredito.ValorTasa} </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Fecha de adjudicación:</b> </div>
                                    <div className="col-lg-6"> {this.mascarafecha(this.state.resultcredito.FechaAdjudicacion)} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Fecha de vencimiento:</b> </div>
                                    <div className="col-lg-6"> {this.mascarafecha(this.state.resultcredito.FechaVencimiento)} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Deuda inicial:</b> </div>
                                    <div className="col-lg-6"> ${this.state.form.monto} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Valor a entregar:</b> </div>
                                    <div className="col-lg-6"> ${this.state.form.monto}</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Cuota</th>
                                        <th scope="col">Fecha de pago</th>
                                        <th scope="col">Capital</th>
                                        <th scope="col">Interés</th>
                                        <th scope="col">Otros</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Saldo capital</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.tablapresuntiva.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.NumeroCuota}</td>
                                                    <td>{this.mascarafecha(data.FechaPago)}</td>
                                                    <td>${data.ValorCapital}</td>
                                                    <td>${data.ValorInteres}</td>
                                                    <td>${data.ValorOtros}</td>
                                                    <td>${data.ValorTotal}</td>
                                                    <td>${data.SaldoCapital}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                </>
            )
        } else {

        }

    }

    mascarafecha = (fecha) => {
        var event = new Date(fecha);
        let date = JSON.stringify(event)
        date = date.slice(1, 11)
        return date;
    }

    loadingShow = () => {
        if (this.state.loading === 'true') {
            return (
                <>
                    <div className="d-flex align-items-center">
                        <strong>Cargando...</strong>
                        <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <SweetAlert2 {...this.state.swal} />
                <div className="row">
                    <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="alert alert-primary" role="alert">
                            <h5 className="texto-justificado mb-0">
                                Simulador de crédito violeta
                            </h5>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 pt-4">
                                    <h6 className="ms-1">Subcalificación</h6>
                                    <select className="form-select" aria-label="Default select example" onChange={this.handleChange} name="subcalificacion" value={this.state.form.subcalificacion} required>
                                        <option value="" >
                                            ...
                                        </option>
                                        {
                                            this.state.subcalificacion.map((data, index) => {
                                                if (data.codigo == '0464' || data.codigo == '0465') {
                                                    return (
                                                        <option value={data.codigo} key={index + 1} >
                                                            {data.nombre}
                                                        </option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-lg-2 pt-4">
                                    <h6 className="ms-1">Monto</h6>
                                    <input className="form-control" type="text" onChange={this.handleChange} name="monto" value={this.state.form.monto} required />
                                </div>
                                <div className="col-lg-2 pt-4">
                                    <h6 className="ms-1">Día fijo</h6>
                                    <input className="form-control" type="text" onChange={this.handleChange} name="diafijo" value={this.state.form.diafijo} required />
                                </div>
                                <div className="col-lg-2 pt-4">
                                    <h6 className="ms-1">Número de cuotas</h6>
                                    <input className="form-control" type="text" onChange={this.handleChange} name="cuotas" value={this.state.form.cuotas} required />
                                </div>
                            </div>
                            <div className="text-end border-top mt-3 border-bottom pb-3 pt-2">
                                <button className="btnndanger me-3" onClick={() => this.limpiar()}>Limpiar</button>
                                <button className="btnn" onClick={() => this.consultarCredito()}>Calcular</button>
                            </div>
                            <br />
                            {this.loadingShow()}
                            {this.mostrarCalculo()}
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SimuladorCreditoVioleta