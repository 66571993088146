import React from "react";
import cosedeSlider from "../../imagenes/COSEDEslider.jpg";

class Cosede extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>COSEDE</h1>
                                <h2 >Depósito Seguro Automático y Gratuito</h2>

                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <a href="https://www.cosede.gob.ec/" target="_blank" className="btn-get-started scrollto">Más información</a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                                <img src={cosedeSlider} className="img-fluid animated rounded" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Protección de depositos</h2>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4 className="texto-justificado ">
                                            Los depósitos realizados en la Cooperativa de Ahorro y Crédito Futuro Lamanense están protegidos por la Corporación del Seguro de Depósitos (COSEDE), la entidad asegura los depósitos realizados en: Cuentas de ahorro, depósitos a la vista y a plazo fijo que se encuentren debidamente contabilizados como pasivos en la Cooperativa.
                                        </h4>

                                        <br />
                                        <h4 className="texto-justificado ">
                                            Para mayor información sobre los montos de cobertura y condiciones del seguro visita <a href="https://www.cosede.gob.ec/" target="_blank">www.cosede.gob.ec</a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default Cosede;