import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Imágenes del slider
import Odontologia from "./../../imagenes/odontologia.jpg";
import Peluqueria from "./../../imagenes/peluqueria.png";
import FondoMortuorio from "./../../imagenes/fondomortuorio.jpg";
import Medicina from "./../../imagenes/medicina.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider-swiper.css";

// Datos de beneficios
const beneficios = [
  {
    title: "Medicina General",
    image: Medicina,
    description:
      "Pensando en el beneficio y comodidad de nuestros socios/as, ponemos a su disposición el servicio de MEDICINA GENERAL.",
    link: "/fondo-mortuorio",
    delay: 100,
  },
  {
    title: "Peluquería",
    image: Peluqueria,
    description:
      "Pensando en el beneficio y comodidad de nuestros socios/as, ponemos a su disposición el servicio de PELUQUERÍA especializada para ambos sexos.",
    link: "/peluqueria",
    delay: 200,
  },
  {
    title: "Odontología",
    image: Odontologia,
    description:
      "Pensando en el beneficio y comodidad de nuestros socios/as, ponemos a su disposición el servicio de ODONTOLOGÍA.",
    link: "/odontologia",
    delay: 300,
  },
  {
    title: "Fondo Mortuorio",
    image: FondoMortuorio,
    description:
      "Servicios de Fondo Mortuorio para nuestros socios. Incluye el servicio de peluquería, odontología y medicina general.",
    link: "/fondo-mortuorio",
    delay: 400,
  },
];

class Beneficios extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Beneficios</h2>
              <p>
                Por ser socio activo de tu cooperativa de ahorro y crédito
                Futuro Lamanense, tienes acceso a los siguientes servicios:
              </p>
            </div>

            <Swiper
              style={{ width: "90%" }}
              slidesPerView={1}
              slidesPerGroupSkip={1}
              spaceBetween={50}
              centeredSlides={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {beneficios.map((beneficio, index) => (
                <SwiperSlide key={index} className="slide">
                  <div
                    className="slide-item"
                    data-aos="zoom-in"
                    data-aos-delay={beneficio.delay}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img
                          src={beneficio.image}
                          className="w-50 rounded"
                          alt={beneficio.title}
                        />
                      </div>
                      <h4>
                        <Link to={beneficio.link}>{beneficio.title}</Link>
                      </h4>
                      <p className="texto-justificado">
                        {beneficio.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Beneficios;
