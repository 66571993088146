import React from "react";
import logoMatriz from "../../imagenes/matriz.png";
import apiSucursal from "../../services/sucursalApi";

class Agencias extends React.Component {

    state = {
        sucursales: [],
        loading: false,
        error: false
    };

    constructor(props, context) {
        super(props, context);
    }

    getSucursales = async () => {
        this.setState({ loading: true });
        try {
            const data = await apiSucursal.sucursal.getall();
            this.setState({ loading: false, error: false, sucursales: data.response });
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    }

    spinner = () => {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    Cargando...
                </div>
            );
        } else {
            return;
        }
    }

    componentDidMount() {
        this.getSucursales();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center text-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1 className="mb-4">NUESTRAS AGENCIAS</h1>
                    <h2 className="mb-4">
                      Únete a la Cooperativa de Ahorro y Crédito Futuro
                      Lamanense, donde nuestras 7 agencias están listas para
                      ofrecerte soluciones financieras innovadoras y un servicio
                      excepcional, ¡porque tu futuro merece lo mejor!
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img src={logoMatriz} className="logovertical" />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    {this.state.sucursales.map((sucursal) => {
                      return (
                        <div
                          className="col-lg-6 pb-4"
                          key={sucursal.idsucursal}
                        >
                          <div className="icon-box">
                            <h4>
                              <a href="">{sucursal.nombre}</a>
                            </h4>
                            <p>
                              <b>Dirección: </b> {sucursal.direccion}
                            </p>
                            <p>
                              <b>Teléfono: </b> {sucursal.telefono}
                            </p>
                            <p>
                              <b>Email: </b> info@futurolamanense.fin.ec
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default Agencias;