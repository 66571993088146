import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlNoticias;

export const fetchApiNodeNoticies = async (method, endPoint, params) => {
  try {
    let url = `${BASE_URL}${endPoint}`;
    let requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      cache: "no-cache",
    };

    if (method === "GET" && params) {
      const queryParams = new URLSearchParams(params);
      url += `?${queryParams.toString()}`;
    } else if ((method === "POST" || method === "PUT") && params) {
      requestOptions.body = JSON.stringify(params);
    }

    const response = await fetch(url, requestOptions);

    // Verificar si la solicitud fue exitosa
    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    // Convertir la respuesta a JSON y retornarla
    return await response.json();
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error;
  }
};
