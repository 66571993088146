import React, { Component } from 'react';
import urlGlobal from '../../../services/urlGlobal';
import "../../../styles/Modal.css";
import ImageComponent from "./ImageComponent";
import EmptyPhoto from '../../../imagenes/noticias/svg/Empty-photo.svg';
const { UrlGlobal } = urlGlobal;

class ModalComponent extends Component {
  // No se necesita un constructor en este caso

  render() {
    const { urlImage, onClose } = this.props;

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ImageComponent
            urlImage={urlImage ? `${UrlGlobal.urlImagenesNoticias}${urlImage}` : EmptyPhoto}
            heightImg={300}
            cursorP={true}
            objectFit="scale-down"
          />
          <button className="modal-close" onClick={onClose}>
            <span className="text-secondary fw-bold">X</span>
          </button>
        </div>
      </div>
    );
  }
}

export default ModalComponent;
