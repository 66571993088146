import React from "react";
import PeluqueriaImg from "../../imagenes/peluqueria.png";
import apiVisitas from "../../services/visitasApi";

class Peluqueria extends React.Component {

    agregarVisitaPeluqueria = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Peluquería" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaPeluqueria();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Servicio de Peluquería</h1>
                                <h2>Queremos verte bien! Aprovecha los beneficios de formar parte de la familia Futuro Lamanense.</h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={PeluqueriaImg} className="img-fluid animated rounded w-50" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                    Pensando en el beneficio y comodidad de nuestros socios/as, ponemos a su disposición el servicio de PELUQUERÍA especializada para ambos sexos.
                                    </h5>
                                    <br />
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                        Agenda tu cita
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                    Ahora puedes agendar tu cita directamente desde nuestra aplicación móvil <a href="https://bit.ly/billeteradigital">Billetera Digital</a> , desde la <a href="https://bit.ly/billeteradigital">web</a> o de forma presencial en atención al cliente en cada una de nuestras agencias.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default Peluqueria;