import React from "react";
import { ToastContainer } from "react-toastify";
// import CreditoConsumoImg from "../../imagenes/consumoim.png";
// import SimuladorCredito from "./simulador-credito";
import apiVisitas from "../../../services/visitasApi";

class CreditoFutuConfianza extends React.Component {
  agregarVisitaCreditoFutuConfianza = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Crédito Futuconfianza" });
    } catch (error) {
      console.log("error al crear visíta " + error);
    }
  };

  componentDidMount() {
    this.agregarVisitaCreditoFutuConfianza();
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Crédito Futuconfianza</h1>
                <h2 className="mb-3">
                  Desde $500 Hasta $10000. Desde 18 a 36 meses plazo
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                {/* <img
                  src={CreditoConsumoImg}
                  className="image-credito-consumo  rounded "
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <h5>
                    Imagina poder hacer crecer tu negocio y alcanzar tus sueños.
                    Este crédito está destinado exclusivamente para nuestros
                    valiosos socios que cuentan con un negocio establecido en
                    comercio, servicios o producción. Ya sea que necesites
                    capital de trabajo o desees invertir en activos fijos,
                    estamos aquí para apoyarte. Este beneficio está disponible
                    únicamente para socios recurrentes que tienen créditos
                    activos en nuestra entidad. Juntos, haremos realidad tus
                    metas.
                  </h5>
                  <br />
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos:
                    </h5>
                  </div>
                  <div>
                    <h5 className="ps-3">
                      - <strong>Socio y Garante</strong>
                    </h5>
                    <ul className="ps-5">
                      <li>
                        <h5>
                          Copia de cédula y certificado de votación del titular
                          y cónyuge (Obligatorio), por parte del garante es
                          opcional (No aplica a socios mayor a 65 años).
                        </h5>
                      </li>
                      <li>
                        <h5>Planilla de Servicios Básicos (Último mes).</h5>
                      </li>
                      <li>
                        <h5>
                          Copia del Impuesto Predial Vigente (Titular o
                          Garante).
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Justificaciones de ingresos si el monto de incremento
                          supera los $2000,00 dólares, caso contrario el
                          desembolso está condicionado (Obligatorio).
                        </h5>
                      </li>
                    </ul>
                    <h5 className="ps-3">
                      - <strong>Con Garantía Hipotecaria</strong>
                    </h5>
                    <ul className="ps-5">
                      <li>
                        <h5>
                          Escritura Original y Constitución de Hipoteca Abierta
                          (Obligatorio).
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Certificado del Registro de la propiedad Libre de
                          Gravamen (Obligatorio).
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Copia del Impuesto Predial Vigente (Obligatorio).
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Avalúo de la propiedad realizado por el Perito
                          calificado de la SEPS o del SBS - si la hipoteca ha
                          superado los 5 años (Obligatorio).
                        </h5>
                      </li>
                    </ul>
                    <h5 className="ps-3">
                      - <strong>Con convenio</strong>
                    </h5>
                    <ul className="ps-5">
                      <li>
                        <h5>
                          Autorización del solicitante a la empresa para el
                          descuento del crédito (Titular).
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Compromiso de la entidad para realizar el descuento
                          mensual (Entidad).
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Tasa de Interés:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Monto hasta $10000 a 36 meses con una tasa de interés del
                    17%.
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Características del crédito:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - <strong>AGILIDAD:</strong> Tu crédito se aprueba en un
                    plazo de 24 a 48 horas.
                  </h5>
                  <h5 className="ps-4">
                    - <strong>FLEXIBILIDAD:</strong> Este crédito se puede
                    adaptar de acuerdo a tus necesidades.
                  </h5>
                  <h5 className="ps-4">
                    - <strong>BENEFICIOS:</strong> Seguro médico para el deudor,
                    cónyuge y 3 dependientes menores de 23 años, acceso a
                    nuestros servicios de medicina general, odontología, peluquería y un fondo
                    mortuorio de hasta $5.000.
                  </h5>
                  <h5 className="ps-4">
                    - <strong>FACILIDAD:</strong> Puedes cancelar las cuotas de
                    tu crédito desde cualquiera de nuestras agencias o mediante
                    transferencias electrónicas.
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Beneficios:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    Si pagas tus cuotas puntuales, participas en los sorteos
                    trimestrales
                  </h5>
                  <br />
                </div>
              </div>
              <br />

              {/* <SimuladorCredito
                codigotipocredito={"014"}
                tipocredito={"consumo"}
              /> */}

              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default CreditoFutuConfianza;
