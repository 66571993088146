import React from "react";
import apiSuscripcion from "../../services/suscripcionApi";
import Suscripcion from "../../models/Suscripcion";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Suscribete extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    state = {
        form: {
            'email': ''
        },
        emailExiste: false
    }

    limpiarForm = () => {
        this.setState({
            form: {
                'email': ''
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    validar = async (e) => {
        e.preventDefault();
        try {
            toast.success("Suscripción realizada correctamente");
            const data = await apiSuscripcion.suscripcion.validar(this.state.form.email);
            if (data.response == null) {
                this.setState({ emailExiste: true });
                let suscripcion = new Suscripcion(this.state.form.email);
                await apiSuscripcion.suscripcion.create(suscripcion);
            } else {
                this.setState({ emailExiste: false });
            }
            this.limpiarForm();
        } catch (error) {
            toast.error("error " + error);
            this.setState({ loading: false, error: true });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="footer-newsletter">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <h4>Suscríbete y mantente informado</h4>
                                <p>Ingresa tu correo electrónico y te mantendremos informado sobre comunicados y eventos de nuestra cooperativa.</p>
                                <form onSubmit={this.validar}>
                                    <input type="email" onChange={this.handleChange} name="email" value={this.state.form.email} required /><button  > Suscríbete</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Suscribete;