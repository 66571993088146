import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApi;

async function callApi(endpoint, options) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiMensajes = {
    mensajes: {
        create(mensaje) {
            return callApi(`/mensaje`, {
                method: 'POST',
                body: JSON.stringify(mensaje),
            });
        }
    }
};

export default apiMensajes;