import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApiGolang;

async function callApi(endpoint, options) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
}

const quejasyreclamosApi = {
    api: {
        tipoformulario() {
            return callApi(`/tipoformulario`);
        },
        estadoformulario() {
            return callApi(`/estadoformulario`);
        },
        agencias() {
            return callApi(`/agencias`);
        },
        provincias() {
            return callApi(`/provincias`);
        },
        ciudades(secuencialDivisionPadre) {
            return callApi(`/ciudad/` + secuencialDivisionPadre);
        },
        agregarFormulario(dataFormulario) {
            return callApi(`/agregarformulario`, {
                method: 'POST',
                body: JSON.stringify(dataFormulario),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        },
        consultaproceso(identificacion) {
            return callApi(`/consulta-procesos/` + identificacion);
        },
    }
};

export default quejasyreclamosApi;