import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApi;

async function callApi(endpoint, options) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiSuscripcion = {
    suscripcion: {

        validar(email) {
            return callApi(`/suscripcion/` + email);
        },

        create(data) {
            return callApi(`/suscripcion`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
        }

    }
};

export default apiSuscripcion;